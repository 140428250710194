import React from "react";

export const Footer: React.FC = () => {
    return (
        <div className="bg-white py-24">
            <div className="mt-4 flex justify-center gap-8">
                <a href="https://www.dextools.io/app/fr/base/pair-explorer/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                    <img className="size-5" src="/dextools.png" alt="telegram"/>
                </a>
                <a href="https://dexscreener.com/base/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                    <img className="size-5" src="/dexscreener.png" alt="telegram"/>
                </a>
                <a href="https://t.me/karatekiki" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                    <img className="size-5" src="/telegram-34.png" alt="telegram"/>
                </a>
                <a href="https://twitter.com/kikithecatxx" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                    <img className="size-5" src="/x-34.png" alt="x"/>
                </a>
            </div>
            <p className="mt-10 text-center text-md leading-5 text-link">© 2024 KIKI. All rights reserved</p>
        </div>
    );
}