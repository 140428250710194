import React from "react";

export const Tokenomics: React.FC = () => {
    return (
        <div className="flex flex-col bg-light-blue place-content-center items-center gap-5 py-20" id="kikinomics">
            <h2 className="lg:text-4xl w-screen font-tiki text-3xl text-center py-10">Kikinomics</h2>
            <div className="w-4/5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                <div className="bg-gradient-to-t from-[#cc2828] to-[#f3563d] border-2 border-white rounded-xl py-10 lg:p-6 text-white font-tiki space-y-6 w-full">
                    <div className="text-3xl lg:text-xl tracking-wider text-center uppercase">
                        Total Supply
                    </div>
                    <div className="text-5xl lg:text-3xl tracking-wider text-center uppercase">
                        1 Trillion
                    </div>
                </div>
                <div className="bg-gradient-to-t from-[#cc2828] to-[#f3563d] border-2 border-white rounded-xl py-10 lg:p-6 text-white font-tiki space-y-6 w-full">
                    <div className="text-3xl lg:text-xl tracking-wider text-center uppercase">
                        Liquidity Pool
                    </div>
                    <div className="text-5xl lg:text-3xl tracking-wider text-center uppercase">
                        100% burnt
                    </div>
                </div>
                <div className="bg-gradient-to-t from-[#cc2828] to-[#f3563d] border-2 border-white rounded-xl py-10 lg:p-6 text-white font-tiki space-y-6 w-full">
                    <div className="text-3xl lg:text-xl tracking-wider text-center uppercase">
                        Airdrop
                    </div>
                    <div className="text-5xl lg:text-3xl tracking-wider text-center uppercase">
                        top 500 wallets on $baldo, $toshi, $degen
                    </div>
                </div>
                <div className="bg-gradient-to-t from-[#cc2828] to-[#f3563d] border-2 border-white rounded-xl py-10 lg:p-6 text-white font-tiki space-y-6 w-full">
                    <div className="text-3xl lg:text-xl tracking-wider text-center uppercase">
                        TAX
                    </div>
                    <div className="text-5xl lg:text-3xl tracking-wider text-center uppercase">
                        0,5%
                    </div>
                </div>
                <div className="bg-gradient-to-t from-[#cc2828] to-[#f3563d] border-2 border-white rounded-xl py-10 lg:p-6 text-white font-tiki space-y-6 w-full">
                    <div className="text-3xl lg:text-xl tracking-wider text-center uppercase">
                        CONTRACT
                    </div>
                    <div className="text-5xl lg:text-3xl tracking-wider text-center uppercase">
                        renounced ownership
                    </div>
                </div>
            </div>
        </div>
    );
}