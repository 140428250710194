import React, { useState } from "react";

export const Navbar: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <nav className="font-tiki bg-light-blue px-8 py-6 justify-between items-center flex mx-auto z-50" id="navbar">
                <div className="gap-x-12 items-center flex ">
                    <a className="p-1.5 -m-1.5" href="/#">
                        <img alt="logo" className="w-12" src="logo.png" />
                    </a>
                    <div className="hidden lg:flex gap-x-8 text-2xl tracking-wider">
                        <a className="text-white" href="#kikinomics">Kikinomics</a>
                    </div>
                </div>
                <div className="lg:hidden flex text-white">
                    <button onClick={() => setMobileMenuOpen(true)} className="flex items-center p-3">
                        <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:flex space-x-4">
                    <div className="flex items-center space-x-4">
                        <a href="https://www.dextools.io/app/fr/base/pair-explorer/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dextools.png" alt="telegram"/>
                        </a>
                        <a href="https://dexscreener.com/base/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dexscreener.png" alt="telegram"/>
                        </a>
                        <a href="https://t.me/karatekiki" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/telegram-34.png" alt="telegram"/>
                        </a>
                        <a href="https://twitter.com/kikithecatxx" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/x-34.png" alt="x"/>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <a href="https://app.uniswap.org/explore/tokens/base/0x77Beada8a3216Ac09Bf37f018F0783931546867F" target="_blank" rel="noreferrer">
                            <button className="bg-gradient-to-t from-[#143A9E] to-[#5897F4] text-white font-tiki px-6 w-full lg:w-32 py-4 xl:py-2 rounded border-2 border-white shadow">Buy now</button>
                        </a>
                    </div>
                </div>
            </nav>
            <div className={"relative z-50 " + (mobileMenuOpen ? 'visible' : 'hidden')}>
                <nav className="fixed top-0 left-0 bottom-0 right-0 flex flex-col py-6 px-6 overflow-y-auto bg-white">
                    <button onClick={() => setMobileMenuOpen(false)} className="ml-auto mr-0">
                        <svg className="h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                    <div>
                        <ul>
                            <li className="mb-1">
                                <a className="block p-4 text-xl font-tiki rounded" href="#kikinomics" onClick={() => setMobileMenuOpen(false)}>Kikinomics</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="pt-6 flex flex-row justify-center text-3xl gap-8">
                        <a href="https://www.dextools.io/app/fr/base/pair-explorer/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dextools.png" alt="telegram"/>
                        </a>
                        <a href="https://dexscreener.com/base/0x3466b8419ab08573bb298dc70f72a7cbb67e7132" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dexscreener.png" alt="telegram"/>
                        </a>
                        <a href="https://t.me/karatekiki" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/telegram-34.png" alt="telegram"/>
                        </a>
                        <a href="https://twitter.com/kikithecatxx" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/x-34.png" alt="x"/>
                        </a>
                        </div>
                        <p className="my-4 text-xs text-center">
                            <span>© 2024 KIKI. All rights reserved</span>
                        </p>
                    </div>
                </nav>
            </div>
        
            {/* 
            <div className="navbar_4">
                    <div className="navbar_4_1">
                        <a className="navbar_4_2" href="#play">{t("navbar.play")}</a>
                    </div>
                    <div className="navbar_4_1">
                        <a className="navbar_4_2" href="#statistics">{t("navbar.statistics")}</a>
                    </div>
                    <div className="navbar_4_1">
                        <a className="navbar_4_2" href="#team">{t("navbar.team")}</a>
                    </div>
                    <div className="navbar_4_1">
                        <a className="navbar_4_2" href="#faq">FAQ</a>
                    </div>
                </div>
                <div className="navbar_5">
                    <div className="navbar_5_1">
                        <select onChange={e =>handleChangeLanguage(e.target.value)} defaultValue={language}>
                            <option value="en">English</option>
                            <option value="fr">French</option>
                        </select>
                        <div className="navbar_5_2">
                            <div className="navbar_5_3" id="lang-select">
                                {t("language")}
                            </div>
                            <div className="navbar_5_4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M 2 4.5 L 6 8.5 L 10 4.5" fill="none" stroke="rgb(0, 0, 0)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbar_6">
                    <a href="/dashboard">
                        <div className="navbar_6_1">
                            <p className="navbar_6_2">Dashboard</p>
                        </div>
                    </a>
                </div>
                <div className="navbar_7"></div>
            </nav>
            */}
        </div>
    );
}