import React from "react";
import { Navbar } from "./Navbar";
import { Main } from "./Main";
import { Tokenomics } from "./Tokenomics";
import { Footer } from "./Footer";

export const Home: React.FC = () => {
  return (
    <div className="main">
      <Navbar/>
      <Main/>
      <Tokenomics/>
      <Footer/>
    </div>
  );
};