import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';

import './App.css';
import './styles/style.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwDm4q0Nv3YI8KYr4SS2rsJYxTAvGzY_o",
  authDomain: "kiki-the-cat.firebaseapp.com",
  projectId: "kiki-the-cat",
  storageBucket: "kiki-the-cat.appspot.com",
  messagingSenderId: "900646447064",
  appId: "1:900646447064:web:75907ad51d917f74f774ad",
  measurementId: "G-XL5EWK5QL3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

console.log(analytics.app.name);

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
      </Routes>
    </BrowserRouter>
  );
}
